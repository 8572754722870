import dayjs from 'dayjs';
import * as React from 'react';
import { useDispatch, useSelector } from "react-redux";
import { useParams } from 'react-router-dom';
import { renderTextField } from '../../../components/inputs';
import API from '../../../services/api';
import * as LayoutActions from '../../../store/Layout';
import AppUserForm from '../forms/form-app-user';

const AppUserProfile = () => {
    
    const dispatch = useDispatch()
    const { userId } = useParams();
    const [user,setUser] = React.useState(null);
    const [lessonViews,setLessonViews] = React.useState([]);

    const getLessonViews = async () => {
        let views = await API.getUsersLessonViews(userId);
        console.log("views",views);
        let groupedViews = {};

        for (let i = 0; i < views.length; i++) {
            let date = dayjs(views[i].createdAt).format('DD/MM/YYYY');
            if (!(date in groupedViews)) {
                groupedViews[date] = [];
            }

            groupedViews[date].push(views[i]);
        }
        setLessonViews(groupedViews);
    }

    const getUser = async () => {
        let user = await API.getAppUser(userId);
        getLessonViews(userId);

        let genreList = {
            null: 'Não respondeu',
            'masculine': "Masculino",
            'feminine': "Feminino",
            'other': "Outro",
        }

        setUser({
            ...user,
            birthDate: dayjs(user.birthDate).format('DD/MM/YYYY'),
            createdAt: dayjs(user.createdAt).format('DD/MM/YYYY [às] HH:mm'),
            lastLoginAt: dayjs(user.lastLoginAt).format('DD/MM/YYYY [às] HH:mm'),
            genre: genreList[user.genre || null]
        });

        dispatch(LayoutActions.setPage({
            title: "Perfil: " + user.name
        }))
    }
    
    // set Page settings
    React.useEffect(() => {
        getUser();
    },[]);

    if (!user) return (<></>)

    const toogleUserStatus = async (user) => {
        await API.toggleAppUser(user._id, !user.active);
        getUser();
    }

    return (
        <div className="row">
            <div className="col-sm-4">
                <div className="card">
                    <div className="card-block">
                        <div className='userProfileBox'>
                            <div className='user-avatar'>
                                <img src={user.avatar?.location || '/assets/images/default-profile.png'} />
                            </div>
                            <AppUserForm onSubmit={()=> {}} initialValues={user} onToggleStatus={(user)=> toogleUserStatus(user)} />

                        </div>
                    </div>
                </div>
            </div>
            <div className="col-sm-8">
                <div className="card">
                    <div className="card-header">
                        <h5>Hello card</h5>
                    </div>
                    <div className="card-block">
                        <div>
                            {Object.keys(lessonViews).map(dia => (
                                <>
                                    <p>{dia}</p>
                                    <table className='table' style={{width:'100%'}}>
                                        <thead>
                                            <tr>
                                                <th>Data</th>
                                                <th>Treino</th>
                                                <th>Status</th>
                                                <th>Nota</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {lessonViews[dia].map(view => (
                                                <tr>
                                                    <td>{dayjs(view.createdAt).format('HH:MM')}</td>
                                                    <td>{view.lessonId?.title}</td>
                                                    <td>{view.status}</td>
                                                    <td>{view.score}</td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
  
export default AppUserProfile;