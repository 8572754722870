import React from 'react';
import { 
    Routes, 
    Navigate,
    BrowserRouter as Router, 
    Route,
    Outlet,
    useLocation
} from 'react-router-dom';
import { store } from './store';
import { connect } from "react-redux";

import Login from './pages/Auth/Login';
import { loginStoreUser } from './store/Auth';
import DashboardRouter from './pages/Dashboard';

class MainRoute extends React.Component {

    constructor(props) {
        super(props);
        store.dispatch(loginStoreUser());
    }

    render() {
        const basename = window?._env?.BASE_URL;
        if (this.props.isFetchingData) return <></>

        return (
            <Router basename={basename}>
                <Routes>
                    <Route path="/login" element={<Login/>} exact />
                    <Route path="/" element={<Navigate to="/dashboard"/>} exact />
                    <Route element={<RequireAuth/>} >
                        <Route path="/dashboard/*" element={<DashboardRouter/>} />
                    </Route>
                </Routes>
            </Router>
        )
    }
}
export default connect((state) => state.auth,null)(MainRoute)

function isAuthenticated(){
    const isAuthenticated = store.getState().auth.isAuthenticated;
    console.log("isAuthenticated",isAuthenticated);
    return isAuthenticated;
}

function RequireAuth() {
    let location = useLocation();

    if (!isAuthenticated()) {
      return <Navigate to="/login" state={{ from: location }} />;
    }
  
    return <Outlet />;
}