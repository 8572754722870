import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import API from '../../services/api';

const initialState = {
  pageSettings: {
      title: "<unamed page>"
  },
}

export const layoutSlice = createSlice({
    name: 'layout',
    initialState,
    reducers: {
        setPage: (state, {payload}) => {
            state.pageSettings = {
                ...state.pageSettings,
                ...payload
            }
        },
    }
})

// Action creators are generated for each case reducer function
export const { setPage } = layoutSlice.actions;
export default layoutSlice.reducer;