import * as React from 'react';
import { useDispatch, useSelector } from "react-redux";
import * as LayoutActions from '../../../store/Layout';
import API from '../../../services/api';
import PageToolbar from '../components/Toolbar';
import { useNavigate } from 'react-router-dom';
import dayjs from 'dayjs';
import AdminUserForm from '../forms/form-admin-user';
import { PrimaryButton } from '../components/CustomElements';
  
const AdminUsers = () => {
    
    const dispatch = useDispatch();
    const [users,setUsers] = React.useState([]);
    const [searchString,setSearchString] = React.useState("");
    const [formVisible,setFormVisible] = React.useState(false);
    const [formError,setFormError] = React.useState("");
    const navigate = useNavigate()

    const getUsers = async () => {
        setUsers(await API.getAdminUsers());
    }
    
    // set Page settings
    React.useEffect( () => {
        // Atualiza o titulo do documento usando a API do browser
        dispatch(LayoutActions.setPage({
            title: "Administradores cadastrados no aplicativo",
            subTitle: 'Lista dos usuários cadastrados como administrador'
        }));
        getUsers();
    },[]);

    const onSearchChange = (searchString) => {
        setSearchString(searchString)
    }

    const openUserProfile = (user) => {
        // navigate('/dashboard/app-users/'+user._id)
    }

    const handleSubmit = async (values) => {
        try {
            console.log(values);
            await API.createAdminUsers(values);
            getUsers();
            alert.success('Usuário criado com sucesso!')
        } catch (error) {
            console.log(error.response.data);
            if(error.data) setFormError(error.response.data.message);
        }
    }

    const handleAddClick = () => {
        setFormVisible(!formVisible);
        setFormError("");
    }

    return (
        <div className='row'>
            <div className="col-12">
                <PageToolbar>
                    <PrimaryButton className={'m-0'} onClick={handleAddClick}>{!formVisible ? 'Criar Admin':'Cancelar'}</PrimaryButton>
                    <PageToolbar.Searchbar onChange={onSearchChange} value={searchString} />
                </PageToolbar>
            </div>
            <div className="col-12">
                <div className='row'>
                    {formVisible && (
                        <div className='col-md-4' >
                            <div className='card' style={{}}>
                                <div className='card-block'>
                                    <AdminUserForm
                                        // alertComponent={<AlertDanger className='mb-3' dismissable onClose={() => setFormError("")} message={formError}/>}
                                        onClose={() => handleAddClick()}
                                        onCloseError={()=>setFormError("")}
                                        formError={formError}
                                        onSubmit={(data)=>handleSubmit(data)}/>
                                </div>
                            </div>
                        </div>
                    )}
                    <div className={formVisible ? 'col-sm-8' : 'col-12'}>
                        <div className="card">
                            <div className="table-card-block">
                                <div className="table-responsive">
                                    <table className="table table-hover clicable">
                                        <thead>
                                            <tr>
                                                <th style={{textAlign:'center'}}>#</th>
                                                <th>Nome</th>
                                                <th>E-mail</th>
                                                <th>Cadastrado em</th>
                                                <th>Último login</th>
                                                <th>Status</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {users.map(user => (
                                                <tr onClick={()=> openUserProfile(user)}>
                                                    <td style={{padding:'2px', textAlign:'center'}}>
                                                        <img style={{width:'50px'}} src={user.avatar?.location || "/assets/images/default-profile.png"} className="img-radius" alt="User-Profile-Image"/>
                                                    </td>
                                                    <td>{user.name}</td>
                                                    <td>{user.name}</td>
                                                    <td>{user.email}</td>
                                                    <td>{dayjs(user.createdAt).format('DD/MM/YYYY [às] HH:mm')}</td>
                                                    <td>{dayjs(user.lastLoginAt).format('DD/MM/YYYY [às] HH:mm')}</td>
                                                    <td>{user.active ? 'Ativo':'Inativo'}</td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default AdminUsers;