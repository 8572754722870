import * as React from 'react';
import { useDispatch, useSelector } from "react-redux";
import * as LayoutActions from '../../../../store/Layout';
import API from '../../../../services/api';
import PageToolbar from '../../components/Toolbar';
import { useNavigate } from 'react-router-dom';
import dayjs from 'dayjs';
import './index.scss';
import 'react-chat-elements/dist/main.css';

import { ChatItem, MessageBox, Input, Button } from 'react-chat-elements'
import { PrimaryButton } from '../../components/CustomElements';
import Chat from './Chat';

import { io } from "socket.io-client";
const socket = io("ws://localhost:4000", { autoConnect: false });

const AppUsersMessages = () => {
    
    const dispatch = useDispatch();
    const [rooms,setRooms] = React.useState([]);
    const [searchString,setSearchString] = React.useState("");
    const [selectedRoom, selectRoom] = React.useState(null);
    const [lastMessage, setLastMessage] = React.useState(null);
    let { user, accessToken } = useSelector((state) => state.auth);
    const navigate = useNavigate();


    const getRooms = async (type) => {
        setRooms(await API.getRooms(type));
    }
    
    // set Page settings
    React.useEffect( () => {
        // Atualiza o titulo do documento usando a API do browser
        dispatch(LayoutActions.setPage({
            title: "Associados cadastrados no aplicativo",
            subTitle: 'Lista dos usuários cadastrados no aplicativo'
        }));
        getRooms('direct');
        connectToSocket();
    },[]);

    const connectToSocket = () => {
        socket.auth = {accessToken};
        socket.connect();

        socket.on('connect', () => {
            console.log('connected')
        })
    
        socket.on("connect_error", (err) => {
            console.log(err);
        });
    
        socket.on("receive_message", (message) => {
            console.log('new message', message);
            setLastMessage(message.data);
        });
    }

    const onSearchChange = (searchString) => {
        setSearchString(searchString)
    }

    const openRoom = (room) => {
        selectRoom(room)
    }

    const renderChat = () => {
        if (!selectedRoom) return null;
        return <Chat 
        socket={socket} room={selectedRoom} lastMessage={lastMessage || null} />
    }

    return (
        <div className='row'>
            <div className='col-5'>
                <div className='row'>
                    <div className="col-xl-12">
                        <PageToolbar>
                            <PageToolbar.Searchbar onChange={onSearchChange} value={searchString} />
                        </PageToolbar>
                    </div>
                    <div className="col-xl-12">
                        <div className="card">
                            <div className="chat-user-list">
                                {rooms.map(room => (
                                    <ChatItem
                                        onClick={() => openRoom(room)}
                                        avatar={room.coverImage?.location || "/assets/images/default-profile.png"}
                                        alt={room.title[0]}
                                        title={room.title}
                                        subtitle={room.lastMessage?.text}
                                        date={new Date()}
                                        unread={room.unseenCount} />
                                ))}
                                
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='col-7'>
                <div className='row'>
                    { renderChat() }
                </div>
            </div>

        </div>
    );
}

export default AppUsersMessages;