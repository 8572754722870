import * as React from 'react';
import { useSelector } from "react-redux";
import { Route, Routes } from 'react-router-dom';

import './index.scss';

import PageHeader from './components/PageHeader';

import Dashboard from './pages/Dashboard';
import Cursos from './pages/Cursos';
import AppUsers from './pages/AppUsers';
import NavigationMenu from './components/NavigationMenu';
import Header from './components/Header';
import MainBody from './components/MainBody';
import Videos from './pages/Videos';
import Classes from './pages/Classes';
import AddLesson from './pages/AddLesson';
import News from './pages/News';
import AddNews from './pages/AddNews';
import AppUserProfile from './pages/AppUserProfile';
import AppUsersMessages from './pages/AppUsersMessages';
import AdminUsers from './pages/AdminUsers';

const DashboardRouter = () => {
    const {pageSettings} = useSelector((state) => state.layout);

    return (
        <>
            <NavigationMenu/>
            <Header/>
            <MainBody>
                <PageHeader />
                <div className='main-body'>
                    <div className='page-wrapper container'>
                        <Routes>
                            <Route path="/" element={<Dashboard/>} exact />
                            <Route path="/courses" element={<Cursos/>} exact />
                            <Route path="/courses/:courseId" element={<Classes/>} />
                            <Route path="/courses/:courseId/add" element={<AddLesson/>} />
                            <Route path="/courses/:courseId/edit/:lessonId" element={<AddLesson/>} />
                            <Route path="/app-users" element={<AppUsers/>} exact />
                            <Route path="/app-users/:userId" element={<AppUserProfile/>} exact />
                            <Route path="/admin-users" element={<AdminUsers/>} exact />
                            <Route path="/admin-users/:userId" element={<AppUserProfile/>} exact />
                            <Route path="/chat" element={<AppUsersMessages/>} exact />
                            <Route path="/videos" element={<Videos/>} exact />
                            <Route path="/news/edit/:newsId" element={<AddNews/>} exact />
                            <Route path="/news/add" element={<AddNews/>} exact />
                            <Route path="/news" element={<News/>} exact />
                        </Routes>

                    </div>
                </div>
            </MainBody>
        </>
    );
}

export default DashboardRouter;