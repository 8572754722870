import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { logoutUser } from '../../../store/Auth';

export default function Header(props) {
    let { user } = useSelector((state) => state.auth);
    let dispatch = useDispatch();

    const handleLogout = () => {
        dispatch(logoutUser());
    }

    return (
            <header className="navbar pcoded-header navbar-expand-lg navbar-light">
            <div className="m-header">
                <a className="mobile-menu" id="mobile-collapse1"><span></span></a>
                <Link to="/" className="b-brand">
                    <img src='/assets/images/logo-thumb.png' style={{width:'35px'}}/>
                    <span className="b-title">FiClub</span>
                </Link>
            </div>
            <a className="mobile-menu" id="mobile-header">
                <i className="feather icon-more-horizontal"></i>
            </a>
            <div className="collapse navbar-collapse">
                <ul className="navbar-nav ml-auto">
                    <li>
                        <div className="dropdown">
                            <a className="dropdown-toggle" data-toggle="dropdown"><i className="icon feather icon-bell"></i></a>
                            <div className="dropdown-menu dropdown-menu-right notification">
                                <div className="noti-head">
                                    <h6 className="d-inline-block m-b-0">Notifications</h6>
                                    <div className="float-right">
                                        <a className="m-r-10">mark as read</a>
                                        <a>clear all</a>
                                    </div>
                                </div>
                                <ul className="noti-body">
                                    <li className="n-title">
                                        <p className="m-b-0">NEW</p>
                                    </li>
                                    <li className="notification">
                                        <div className="media">
                                            <img className="img-radius" src="/assets/images/default-profile.png" alt="Generic placeholder image"/>
                                            <div className="media-body">
                                                <p><strong>John Doe</strong><span className="n-time text-muted"><i className="icon feather icon-clock m-r-10"></i>30 min</span></p>
                                                <p>New ticket Added</p>
                                            </div>
                                        </div>
                                    </li>
                                    <li className="n-title">
                                        <p className="m-b-0">EARLIER</p>
                                    </li>
                                    <li className="notification">
                                        <div className="media">
                                            <img className="img-radius" src="/assets/images/default-profile.png" alt="Generic placeholder image"/>
                                            <div className="media-body">
                                                <p><strong>Joseph William</strong><span className="n-time text-muted"><i className="icon feather icon-clock m-r-10"></i>30 min</span></p>
                                                <p>Prchace New Theme and make payment</p>
                                            </div>
                                        </div>
                                    </li>
                                    <li className="notification">
                                        <div className="media">
                                            <img className="img-radius" src="/assets/images/default-profile.png" alt="Generic placeholder image"/>
                                            <div className="media-body">
                                                <p><strong>Sara Soudein</strong><span className="n-time text-muted"><i className="icon feather icon-clock m-r-10"></i>30 min</span></p>
                                                <p>currently login</p>
                                            </div>
                                        </div>
                                    </li>
                                </ul>
                                <div className="noti-footer">
                                    <a>show all</a>
                                </div>
                            </div>
                        </div>
                    </li>
                    <li>
                        <div className="dropdown drp-user">
                            <a className="dropdown-toggle" data-toggle="dropdown">
                                <i className="icon feather icon-settings"></i>
                            </a>
                            <div className="dropdown-menu dropdown-menu-right profile-notification">
                                <div className="pro-head">
                                    <img src="/assets/images/default-profile.png" className="img-radius" alt="User-Profile-Image"/>
                                    <span>{user.name}</span>
                                    <a href="#" onClick={() => handleLogout()} className="dud-logout" title="Logout">
                                        <i className="feather icon-log-out"></i>
                                    </a>
                                </div>
                                <ul className="pro-body">
                                    <li><a className="dropdown-item"><i className="feather icon-settings"></i> Settings</a></li>
                                    <li><a className="dropdown-item"><i className="feather icon-user"></i> Profile</a></li>
                                    <li><a href="message.html" className="dropdown-item"><i className="feather icon-mail"></i> My Messages</a></li>
                                    <li><a href="auth-signin.html" className="dropdown-item"><i className="feather icon-lock"></i> Lock Screen</a></li>
                                </ul>
                            </div>
                        </div>
                    </li>
                </ul>
            </div>
        </header>
    );
}
