import * as React from 'react';
import { useSelector } from 'react-redux';

export default function PageHeader(props) {

    const {pageSettings} = useSelector((state) => state.layout);

    return (
        <div className="page-header">
            <div className="page-block">
                <div className="row align-items-center">
                    <div className="col-md-12">
                        <div className="page-header-title">
                            <h5 className="m-b-10">{pageSettings.title}</h5>
                        </div>
                        <ul className="breadcrumb">
                            <li className="breadcrumb-item"><a href="/"><i className="feather icon-home"></i></a></li>
                            <li className="breadcrumb-item"><a href="">{pageSettings.title}</a></li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    );
}
