import * as React from 'react';
import { useDispatch, useSelector } from "react-redux";
import * as LayoutActions from '../../../store/Layout';
import API from '../../../services/api';
import PageToolbar from '../components/Toolbar';
import { useNavigate } from 'react-router-dom';
import dayjs from 'dayjs';
  
const AppUsers = () => {
    
    const dispatch = useDispatch();
    const [users,setUsers] = React.useState([]);
    const [searchString,setSearchString] = React.useState("");
    const navigate = useNavigate()

    const getUsers = async () => {
        setUsers(await API.getAppUsers());
    }
    
    // set Page settings
    React.useEffect( () => {
        // Atualiza o titulo do documento usando a API do browser
        dispatch(LayoutActions.setPage({
            title: "Associados cadastrados no aplicativo",
            subTitle: 'Lista dos usuários cadastrados no aplicativo'
        }));
        getUsers();
    },[]);

    const onSearchChange = (searchString) => {
        setSearchString(searchString)
    }

    const openUserProfile = (user) => {
        navigate('/dashboard/app-users/'+user._id)
    }

    return (
        <div className='row'>
            <div className="col-xl-12">
                <PageToolbar>
                    <PageToolbar.Searchbar onChange={onSearchChange} value={searchString} />
                </PageToolbar>
            </div>
            <div className="col-xl-12">
                <div className="card">
                    <div className="table-card-block">
                        <div className="table-responsive">
                            <table className="table table-hover clicable">
                                <thead>
                                    <tr>
                                        <th style={{textAlign:'center'}}>#</th>
                                        <th>Nome</th>
                                        <th>E-mail</th>
                                        <th>Cadastrado em</th>
                                        <th>Último login</th>
                                        <th>Status</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {users.map(user => (
                                        <tr onClick={()=> openUserProfile(user)}>
                                            <td style={{padding:'2px', textAlign:'center'}}>
                                                <img style={{width:'50px'}} src={user.avatar?.location || "/assets/images/default-profile.png"} className="img-radius" alt="User-Profile-Image"/>
                                            </td>
                                            <td>{user.name}</td>
                                            <td>{user.email}</td>
                                            <td>{dayjs(user.createdAt).format('DD/MM/YYYY [às] HH:mm')}</td>
                                            <td>{dayjs(user.lastLoginAt).format('DD/MM/YYYY [às] HH:mm')}</td>
                                            <td>{user.active ? 'Ativo':'Inativo'}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    );
}

export default AppUsers;