import * as React from 'react';
import { Form, Field } from 'react-final-form';
import { FORM_ERROR } from 'final-form';

import { renderSingleCheckboxField, renderTextField } from '../../../components/inputs';
import { PrimaryButton } from '../../Dashboard/components/CustomElements';

const LoginForm = (props) => {
    return (
        <Form
            onSubmit={props.onSubmit}
            validate={props.validate || null}
            initialValues={props.initialValues}
            render={({ handleSubmit, submitting, pristine }) => (
                <form onSubmit={handleSubmit}>
                    <Field
                        required
                        name="email"
                        type='email'
                        placeholder="E-mail"
                        component={renderTextField}
                        />
                    <Field
                        required
                        name="password"
                        type='password'
                        placeholder="Senha"
                        component={renderTextField}
                        />
                    <Field
                        name="remember-email"
                        label='Lembrar email'
                        component={renderSingleCheckboxField}
                        />

                    {props.alertComponent}
                    <PrimaryButton isLoading={props.isLoading} className='mb-3' type="submit" label='Login' disabled={submitting || pristine || props.isLoading} />
                </form>
            )}/>
    );
}

export default LoginForm;