import * as React from 'react';
import { Form, Field } from 'react-final-form';
import { FORM_ERROR } from 'final-form';

import { renderHiddenField, renderTextField, FileField, renderSelectField } from '../../../components/inputs';
import { DangerButton, PrimaryButton, SecondaryOutlineButton } from '../components/CustomElements';
import { AlertDanger } from '../components/Alerts';

const formStyle = {
    field: {
        marginBottom: '10px'
    }
}

const ADMIN_ACCESS_LEVELS = [
    {key:'master', name: 'Master'},
    {key:'author', name: 'Autor/Editor'},
]

const AdminUserForm = (props) => {
    return (
        <Form
            onSubmit={props.onSubmit}
            validate={props.validate || null}
            initialValues={props.initialValues}
            render={({ handleSubmit, submitting, pristine, values }) => (
                <form component="form" onSubmit={handleSubmit} noValidate >
                    <Field
                        fullWidth
                        name="name"
                        component={renderTextField}
                        label="Nome"
                        />

                    <Field
                        fullWidth 
                        name="email"
                        type='email'
                        component={renderTextField}
                        label="Email"
                        />

                    <Field
                        fullWidth
                        name="password"
                        type='password'
                        component={renderTextField}
                        label="Senha"
                        />

                    <Field
                        name="accessLevel"
                        component={renderSelectField}
                        label="Selecione o tipo"
                    >
                        {ADMIN_ACCESS_LEVELS.map(option => {
                            return <option value={option.key}>{option.name}</option>
                        })}
                    </Field>
                    <AlertDanger className='mb-3' dismissable onClose={props.onCloseError} message={props.formError}/>
                    <PrimaryButton 
                        type="submit"
                        label={"Criar Admin"}
                        disabled={submitting || pristine}/>
                    <SecondaryOutlineButton
                        label='Cancelar'
                        type="button" 
                        onClick={()=> props?.onClose(values)}/>
                </form>
            )}/>
    );
}

export default AdminUserForm;