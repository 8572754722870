import * as React from 'react';

PageToolbar.Searchbar = (props) => {
    return(
        <div className='search-box-div' style={{width: '350px', position:'relative'}}>
            <i style={{fontSize: '20px', position:'absolute', top:'13px',left: '14px'}} className="feather icon-search"></i>
            <input type="text" className="form-control" placeholder="Buscar ..." onChange={(e) => props?.onChange(e.target.value)} value={props?.value} />
        </div>
    )
}

export default function PageToolbar({toolbarStyle, ...props}) {
  return (
    <div className='row mb-2 toolbar-div' style={toolbarStyle}>
        <div className='col' style={{display:'flex',flexDirection: 'row'}}>
            {props.children}
        </div>
    </div>
  );
}
