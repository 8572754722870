import React, {useRef} from 'react';
import { Field } from "react-final-form";
import './index.scss';

const renderTextField = ({
    label,
    input,
    meta: { touched, invalid, error },
    ...custom
}) => (
    <div className="form-group">
        {label && <label for="exampleInputEmail1">{label}</label>}
        <input className="form-control" {...input} {...custom}/>
    </div>
)

const TagsInputField = ({
    label,
    input: { value, onChange, ...input },
    meta: { touched, invalid, error },
    ...custom
}) => {

    const inputRef = useRef()

    const tagElement = (tag) => tag !== "" && (
        <div className="badge bg-primary tag-item">
            <span>{tag}</span>
            <i class="feather icon-x" onClick={() => removeTag(tag)}></i>
        </div>
    )

    const removeTag = (text) => {
        let tags = value.split(",");
        tags = tags.filter( tag => tag !== text);
        onChange(tags.join(","))
        console.log(tags);
    }

    const addTag = (value) => {
        if (value !== "" && !valueTags.includes(value.toLowerCase())) {
            valueTags.push(value.toLowerCase());
            onChange(valueTags.join(","));
            inputRef.current.value = ""
        }
    }

    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            event.preventDefault();
            addTag(event.target.value)
        }
    }

    let valueTags = value ? value.split(",") : [];

    return (
        <>
            <div>{JSON.stringify(onChange)}</div>
            <div className="form-group">
                {label && <label for="exampleInputEmail1">{label}</label>}
                <div>
                    {
                        valueTags.length > 0 && valueTags.map( tag => tagElement(tag))
                    }
                </div>
                <input ref={inputRef} className="form-control" autoComplete='off' {...input} {...custom} onKeyDown={handleKeyDown}/>
            </div>
        </>
    )
}

const renderSingleCheckboxField = ({
    label,
    input,
    meta: { touched, invalid, error },
    ...custom
}) => (
    <div className="form-group text-left">
        <div className="checkbox checkbox-fill d-inline">
            <input id={"id-"+input.name} type="checkbox" {...input}/>
            <label for={"id-"+input.name} className="cr">{label}</label>
        </div>
    </div>
)

const renderSelectField = ({
    children,
    label,
    input,
    meta: { touched, invalid, error },
    ...custom
}) => (
    <div className="form-group">
        <label for={"id-"+input.name}>{label}</label>
        <select className="form-control" id={"id-"+input.name} {...input}>
            {children}
        </select>
    </div>
)

const renderHiddenField = ({input}) => (
    <input
        {...input}
        type='hidden'
    />
)

// // const renderFileField = ({input: {onChange, input} , label, meta, style}) => (
    
// //             {input.value != "" ? 'alterar' : label}
// //             <input {...input} type="file" hidden onChange={({ target }) => onChange(target.files)} />
        
// // )

const FileField = ({ name, ...props }) => (
    <div className="form-group">
        <Field name={name}>
            {({ input: { value, onChange, ...input } }) => (
                <>
                    <label htmlFor={"id-"+input.name} className="btn shadow-1 btn-outline-secondary">
                        {props.label}
                        <input
                            id={"id-"+input.name}
                            {...input}
                            type="file"
                            hidden
                            onChange={({ target }) => {onChange(target.files)}} // instead of the default target.value
                            {...props} />
                    </label>
                    <span style={{marginLeft: '5px'}}>{value.length >= 1 && value[0].name}</span>
                </>
            )}
        </Field>
    </div>
  );

export {
    renderTextField,
    renderSingleCheckboxField,
    renderHiddenField,
    renderSelectField,
    FileField,
    TagsInputField,
}