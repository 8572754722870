import React, { useEffect, useState } from "react";
import { MessageBox, Input} from 'react-chat-elements';
import API from "../../../../services/api";
import { PrimaryButton } from '../../components/CustomElements';
import { useSelector } from "react-redux";
import { io } from "socket.io-client";
const socket = io("ws://localhost:4000", { autoConnect: false });

export default function Chat({room, socket, lastMessage}) {

  const [messages, setMessages] = useState([]);
  const [message, setMessage] = useState('');
  let { user, accessToken } = useSelector((state) => state.auth);

  useEffect(() => {
    getMessages();
    return () => {}
  },[])

  useEffect(() => {
    console.log(lastMessage);
    if (lastMessage && lastMessage.roomId === room._id) {
        setMessages([...messages, lastMessage]);
    }
    return () => {}
  },[lastMessage])

  const getMessages = async () => {
    const messagesResponse = await API.getRoomMessages(room._id);
    console.log(messagesResponse);
    setMessages(messagesResponse.sort((a,b) => b.createdAt - a.createdAt));
  }

  const onMessage = () => {
    if (message.trim() === "")
        return;

    socket.emit("send_message", {
        content: message,
        to: room._id,
    });
    setMessage('');
  }

  console.log(messages);

  return (
    <div className="col-12">
        <div className="card">
            <div className="card-header user-chat-header">
                <div className='user-avatar'>
                    <img
                        style={{width:'50px'}}
                        src={room.coverImage.location || "/assets/images/default-profile.png"}
                        className="img-radius"
                        alt="User-Profile"/>
                </div>
                <div className='user-info'>
                    <span className='user-name'>{room.title}</span><br/>
                    <span>Email do usuário</span>
                </div>
            </div>
            <div className="message-box-body">
                <div style={{display: 'flex',flexDirection: 'column-reverse'}}>
                    {messages.map(message => (
                        <MessageBox
                            date={new Date(message.createdAt)}
                            position={message.sender === user._id ? 'right':'left'}
                            type={'text'}
                            text={message.body}
                        />
                    ))}
                </div>
            </div>
            <div className='chat-input-box'>
                <Input
                    onChange={(e) => setMessage(e.target.value)}
                    onSub
                    value={message}
                    placeholder="Escreva aqui..."
                    rightButtons={
                        <PrimaryButton 
                            className={'m-0'}
                            label="Enviar"
                            onClick={() => onMessage()}
                        />
                    }
                />
            </div>
        </div>
    </div>
)
}