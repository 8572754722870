import * as React from 'react';
import { Form, Field } from 'react-final-form';
import { FORM_ERROR } from 'final-form';

import { Editor } from "react-draft-wysiwyg";
import { EditorState } from "draft-js";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

import { renderHiddenField, renderTextField, renderSelectField, FileField } from '../../../components/inputs';
import { PrimaryOutlineButton, SecondaryOutlineButton } from '../components/CustomElements';

const formStyle = {
    field: {
        marginBottom: '10px'
    }
}

const NewsForm = (props) => {
    
    return (
        <Form
            onSubmit={props.onSubmit}
            validate={props.validate || null}
            initialValues={props.initialValues}
            render={({ handleSubmit, submitting, pristine }) => (
                <form onSubmit={handleSubmit} className='row'>
                    <div className='col-7'>
                        <div className='row'>
                            <div className='col-12'>
                                <div className='card'>
                                    <div className='card-block'>
                                        <Field
                                            name="title"
                                            component={renderTextField}
                                            label="Titulo do Treino"
                                            />

                                        <Field
                                            name="subTitle"
                                            component={renderTextField}
                                            label="Chamada da noticia"
                                            />
                                    </div>
                                </div>
                            </div>
                            <div className='col-12'>
                                <div className='card'>
                                    <div className='card-block'>
                                        <div>
                                            <span>Conteúdo escrito:</span>
                                        </div>
                                        <Field
                                            name="contentHtml"
                                            >
                                            {({ input: { value, onChange, ...input } }) => (
                                                <Editor 
                                                toolbar={{
                                                    options: ['inline', 'fontSize', 'list', 'textAlign', 'link', 'image'],
                                                }}
                                                    placeholder="Começe a escrever o conteúdo do treino aqui..."
                                                    editorState={value || EditorState.createEmpty()} onEditorStateChange={onChange} {...input} />
                                                    )}
                                        </Field>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='col-5'>
                        <div className='card'>
                            <div className='card-block'>
                                <Field name="_id"  component={renderHiddenField}/>
                                <div className='text-center'>
                                    {
                                        props.initialValues?.coverImage?.location && 
                                        <img style={{marginBottom:'5px',width:'auto', maxHeight: '200px'}} src={props.initialValues?.coverImage?.location} />
                                    }
                                </div>
                                <Field
                                    name="postedAt"
                                    component={renderTextField}
                                    type='date'
                                    label="Data da postagem"
                                    />

                                <Field
                                    name="active"
                                    component={renderSelectField}
                                    label="Status"
                                >
                                    <option value={0}>Não postado</option>
                                    <option value={1}>Postado</option>
                                </Field>
                                <FileField
                                    label="Escolher imagem"
                                    name="file"
                                    multiple={false}
                                    />
                                <PrimaryOutlineButton
                                    type="submit" 
                                    isLoading={props.isLoading}
                                    disabled={submitting || pristine || props.isLoading}
                                    label="Salvar"/>
                                <SecondaryOutlineButton 
                                    type="button" 
                                    onClick={props.onClose}
                                    label="Cancelar"/>
                                { props.alertComponent }
                            </div>
                        </div>
                    </div>
                </form>
            )}/>
    );
}

export default NewsForm;