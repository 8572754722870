import * as React from 'react';

const AlertDanger = (props) => {
    return(
        ( props.children || props.message ) && 
        <div 
            style={{marginBottom:0, padding: 10}} 
            className={"alert alert-danger " + props.className}
            role="alert"
        >
            {props.children || props.message }

            {props.dismissable && 
            <button type="button" className="close" onClick={props.onClose}>
                <span aria-hidden="true">&times;</span>
            </button>}
        </div>
    )
}

export {
    AlertDanger
}