import * as React from 'react';
import { useDispatch, useSelector } from "react-redux";
import * as LayoutActions from '../../../store/Layout';
import API from '../../../services/api';

import ClassForm from '../forms/form-class';
import { useNavigate, useParams } from 'react-router-dom';
import { convertToRaw } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import { EditorState, ContentState } from 'draft-js';
import { AlertDanger } from '../components/Alerts';
import { FORM_ERROR } from 'final-form';
import { useAlert } from 'react-alert'

const cursoItemStyle = {
    image: {
        width:'120px',
        display:'block',
        marginLeft:'auto',
        marginRight:'auto'
    },
    text: {
        display:'block',
        fontSize: '18px',
        marginTop: '15px'
    }
};

const AddLesson = () => {
    
    const dispatch = useDispatch();
    const [videos,setVideos] = React.useState([]);
    const [lesson, setLesson] = React.useState({});
    const { courseId, lessonId } = useParams();
    const [formError,setFormError] = React.useState("");
    const navigate = useNavigate();
    const alert = useAlert()

    const getLesson = async () => {
        let data = await API.getLesson(lessonId);

        const { contentBlocks, entityMap } = htmlToDraft(data.contentHtml);
        const contentState = ContentState.createFromBlockArray(contentBlocks, entityMap);
        data.contentHtml = EditorState.createWithContent(contentState);
        data.tags = data.tags.join(",")

        setLesson(data)
    }
        
    // set Page settings
    React.useEffect(() => {
        // Atualiza o titulo do documento usando a API do browser
        dispatch(LayoutActions.setPage({
            title: lessonId ? "Editar Treino" :"Novo Treino",
        }));

        if (lessonId) { getLesson() }

        getVideos();

    },[]);

    const handleSubmit = (data) => {
        let contentHtml = '';

        if (data.contentHtml) {
            contentHtml = convertToRaw(data.contentHtml.getCurrentContent());
            contentHtml = draftToHtml(contentHtml);
        }

        if (!data.file && !data._id) {
            return {[FORM_ERROR]: "Insira um icone para o treino"}    
        }

        addLesson(data,contentHtml)
    }

    const addLesson = async (values,contentHtml) => {
        const payload = new FormData();
        payload.append("title", values.title);
        payload.append("subTitle", values.subTitle);
        payload.append("videoId", values.videoId);
        payload.append("contentHtml", contentHtml);
        payload.append("courseId", courseId);
        payload.append("tags", values.tags);
        if('file' in values) payload.append("file", values.file[0]);

        try {
            if ('_id' in values) {
                let response = await API.editClass(values._id,payload);
                getLesson();
                alert.success('Treino atualizado com sucesso!')
            } else {
                let response = await API.addClass(payload);
                navigate('/dashboard/courses/' + courseId)
            }
        } catch (error) {
            if(error.data) setFormError(error.data.message);
        }
    }

    const getVideos = async () => {
        try {
            let videos = await API.getVideos();
            setVideos(videos);
        } catch (error) {
            console.log(error.response);
        }
    }

    return (
        <ClassForm 
            initialValues={lesson}
            onSubmit={(data) => handleSubmit(data)}
            videoOptions={videos}
            onClose={() => navigate('/dashboard/courses/' + courseId)}
            alertComponent={<AlertDanger dismissable onClose={() => setFormError("")} message={formError}/>}
            />
    );
}

export default AddLesson;