import apiDataSource from "../data-source/api";

export default class CourseService {
  _api = apiDataSource;

  mudarOrdemCurso = async (id, {anterior, proximo}) => {
    try {
        let { data } = await this._api.post('admin/course/edit-course-order/' + id, {anterior, proximo});
        return data;
    } catch (err) {
        throw(err.response)
    }
  }
}