import axios from 'axios';
import { store } from '../store';

const urlApi = process.env.REACT_APP_URL_API;

const apiDataSource = axios.create({
  baseURL: urlApi,
});

apiDataSource.interceptors.request.use(
  (config) => {
    const authToken = store.getState().auth.accessToken;

    if (authToken) {
      config.headers.authorization = authToken;
    }

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export default apiDataSource;