import axios from 'axios';
import { store } from './../store';

const urlApi = process.env.REACT_APP_URL_API;

const authAPI = axios.create({
    baseURL: urlApi,
    headers: {
        "authorization": "1cc6f0e2-1cf4-4bd9-81e5-a6fb64260639"
    }
});

const API = axios.create({
    baseURL: urlApi,
});

const authHeader = (token) => {
    return {
        "authorization": token || store.getState().auth.accessToken
    }
}

const getUsername = async (email) => {
    try {
        let { data } = await authAPI.post('admin/auth/get-username',{email});
        return data;
    } catch (err) {
        throw(err)
    }
}

const login = async (username,password) => {
    try {
        const { data } = await authAPI.post("admin/auth/get-token",{username,password});
        return data;
    } catch (err) {
        throw(err)
    }
}

const register = async (name,email,password,locale) => {
    try {
        const { data } = await authAPI.post("admin/auth/sign-up",{name,email,password,locale});
        return data;
    } catch (err) {
        return {
            error: true,
            message: err
        }
    }
}

const refreshToken = async (token) => {
    try {
        const { data } = await authAPI.post("admin/auth/refresh-token",{token});
        return data;
    } catch (err) {
        throw(err)
    }
}

const getUser = async (token) => {
    try {
        let { data } = await API.get('admin/user/get-user', {headers:authHeader(token)});
        return data;
    } catch (err) {
        throw(err)
    }
}

const getCourses = async () => {
    try {
        let { data } = await API.get('admin/course/get-courses', {headers:authHeader()});
        return data;
    } catch (err) {
        throw(err)
    }
}

const getVideos = async (body) => {
    try {
        let { data } = await API.get('admin/video/get-videos', {headers:authHeader()});
        return data;
    } catch (err) {
        throw(err)
    }
}

const addVideo = async (body) => {
    try {
        let { data } = await API.post('admin/video/add-video',body, {headers:authHeader()});
        return data;
    } catch (err) {
        throw(err)
    }
}

const editVideo = async (id, body) => {
    try {
        let { data } = await API.post('admin/video/edit-video/' + id ,body, {headers:authHeader()});
        return data;
    } catch (err) {
        throw(err)
    }
}

const uploadVideo = async (id,body,onUploadProgress,onDownloadProgress) => {
    try {
        let { data } = await API.post('admin/video/upload-video/'+id,body,{
            headers:authHeader(),
            onUploadProgress,
            onDownloadProgress
        });
        return data;
    } catch (err) {
        throw(err)
    }
}

const deleteVideo = async (id) => {
    try {
        let { data } = await API.delete('admin/video/delete-video/'+id, { headers:authHeader() });
        return data;
    } catch (err) {
        throw(err.response)
    }
}

const getAppUsers = async () => {
    try {
        let { data } = await API.get('admin/user/get-appusers', {headers:authHeader()});
        return data;
    } catch (err) {
        throw(err)
    }
}

const getAdminUsers = async () => {
    try {
        let { data } = await API.get('admin/user/get-adminusers', {headers:authHeader()});
        return data;
    } catch (err) {
        throw(err)
    }
}

const createAdminUsers = async (body) => {
    try {
        let { data } = await API.post('admin/user/create-adminusers',body,{headers:authHeader()});
        return data;
    } catch (err) {
        throw(err)
    }
}

const getAppUser = async (id) => {
    try {
        let { data } = await API.get('admin/user/get-user/'+id, {headers:authHeader()});
        return data;
    } catch (err) {
        throw(err)
    }
}

const getUsersLessonViews = async (id) => {
    try {
        let { data } = await API.get('admin/user/get-users-lesson-views/'+id, {headers:authHeader()});
        return data;
    } catch (err) {
        throw(err)
    }
}

const toggleAppUser = async (id,status) => {
    try {
        let { data } = await API.get('admin/user/'+ (status ? 'enable':'disable') +'-user/'+id, {headers:authHeader()});
        return data;
    } catch (err) {
        throw(err)
    }
}

const addCourse = async (body) => {
    try {
        let { data } = await API.post('admin/course/add-course',body, {headers:authHeader()});
        return data;
    } catch (err) {
        throw(err.response)
    }
}

const editCourse = async (id, body) => {
    try {
        let { data } = await API.post('admin/course/edit-course/' + id,body, {headers:authHeader()});
        return data;
    } catch (err) {
        throw(err.response)
    }
}

const addClass = async (body) => {
    try {
        let { data } = await API.post('admin/lesson/add-lesson',body, {headers:authHeader()});
        return data;
    } catch (err) {
        throw(err.response)
    }
}

const editClass = async (id, body) => {
    try {
        let { data } = await API.post('admin/lesson/edit-lesson/' + id ,body, {headers:authHeader()});
        return data;
    } catch (err) {
        throw(err.response)
    }
}

const getLessons = async (id) => {
    try {
        let { data } = await API.get('admin/lesson/get-lessons/'+id, {headers:authHeader()});
        return data;
    } catch (err) {
        throw(err)
    }
}

const getLesson = async (id) => {
    try {
        let { data } = await API.get('admin/lesson/get-lesson/'+id, {headers:authHeader()});
        return data;
    } catch (err) {
        throw(err)
    }
}

const getNews = async (id) => {
    try {
        let { data } = await API.get('admin/news/get-news/' + (id || ''), {headers:authHeader()});
        return data;
    } catch (err) {
        throw(err)
    }
}

const addNews = async (body) => {
    try {
        let { data } = await API.post('admin/news/add-news',body, {headers:authHeader()});
        return data;
    } catch (err) {
        throw(err.response)
    }
}

const editNews = async (id, body) => {
    try {
        let { data } = await API.post('admin/news/edit-news/' + id ,body, {headers:authHeader()});
        return data;
    } catch (err) {
        throw(err.response)
    }
}

const getRooms = async (type) => {
    try {
        let { data } = await API.get('admin/chat/get-rooms' , {params: {type},headers:authHeader()});
        return data;
    } catch (err) {
        throw(err)
    }
}

const getRoomMessages = async (roomId) => {
    try {
        let { data } = await API.get(`admin/chat/get-messages/${roomId}` , {headers:authHeader()});
        return data;
    } catch (err) {
        throw(err)
    }
}

export default {
    login,
    getUsername,
    getUser,
    refreshToken,
    register,
    addCourse,
    editCourse,
    getCourses,
    getAppUser,
    getUsersLessonViews,
    toggleAppUser,
    getAppUsers,
    getAdminUsers,
    createAdminUsers,
    getVideos,
    addVideo,
    editVideo,
    uploadVideo,
    deleteVideo,
    addClass,
    editClass,
    getLessons,
    getLesson,
    getNews,
    addNews,
    editNews,
    getRooms,
    getRoomMessages,
};