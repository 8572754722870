import * as React from 'react';
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import API from '../../services/api';
import { setUser } from '../../store/Auth';
import { AlertDanger } from '../Dashboard/components/Alerts';
import LoginForm from './forms/form-login';

import './index.css';

const Login = (props) => {

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [formError,setFormError] = React.useState(null);
    const [isLoading,setLoading] = React.useState(false);


    const login = async (email, password) => {
        setLoading(true);
        try {

            let { username } = await API.getUsername(email);
            let {user, token, exp} = await API.login(username,password);

            if (user) {
                await dispatch(setUser({user, exp, token}));
                navigate("/dashboard");
            }
            
        } catch (error) {
            console.log("rer",error)
            const { data } = error.response;
            console.log(data);

            if (data) {
                setFormError(data.message);
            }
        } finally {
            setLoading(false);
        }
    }

    const handleSubmit = async (data) => {
        let { email, password } = data;
        login(email, password);
    };

    return (
        <div className="auth-wrapper">
            <div className="auth-content">
                <div className="auth-bg">
                    <span className="r"></span>
                    <span className="r s"></span>
                    <span className="r s"></span>
                    <span className="r"></span>
                </div>
                <div className="card">
                    <div className="card-body text-center">
                        <div className="mb-4">
                            <i className="feather icon-unlock auth-icon"></i>
                        </div>
                        <h3 className="mb-4">Entrar</h3>
                        <LoginForm
                            isLoading={isLoading}
                            alertComponent={<AlertDanger className="mb-3" dismissable onClose={() => setFormError(null)} message={formError}/>}
                            onSubmit={(data) => handleSubmit(data)}/>
                        <p className="mb-2 text-muted">Esqueceu a senha? <a href="auth-reset-password.html">Redefinir</a></p>
                    </div>
                </div>
            </div>
        </div>
    );
}
  
export default Login;