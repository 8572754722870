import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import API from '../../services/api';
import { useNavigate } from "react-router-dom";


const initialState = {
  user: {},
  isAuthenticated: false,
  isFetchingData: true,
  accessToken: null,
  expiration: null
}

export const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        setUser: (state, {payload}) => {
            localStorage.setItem("@AUTH_TOKEN",payload.token);
            localStorage.setItem("@AUTH_EXPIRATION",payload.exp);
            state.user = payload.user;
            state.accessToken = payload.token;
            state.expiration = payload.exp;
            state.isAuthenticated = true;
        },
        setLoading: (state, {payload}) => {
            state.isLoading = payload
        },
        dataFetched: (state) => {
            state.isFetchingData = false
        },
        logoutUser: (state) => {
            localStorage.removeItem("@AUTH_TOKEN");
            localStorage.removeItem("@AUTH_EXPIRATION");
            state.user = {};
            state.isAuthenticated = false;
        },
    }
})

const loginStoreUser = () => async (dispatch) => {
    let token = localStorage.getItem("@AUTH_TOKEN");
    let exp = localStorage.getItem("@AUTH_EXPIRATION");
    try {
        if ( token ) {
            if (new Date(exp * 1000) < new Date() ) {
                try {
                    let data = await API.refreshToken(token);
                    token = data.token;
                    exp = data.exp;
                } catch (error) {
                    dispatch(setLoading(false));
                    dispatch(logoutUser());
                }
            }
            let user = await API.getUser(token);
            if (user) {
                dispatch(setLoading(false));
                dispatch(setUser({user, exp, token}));
            }
        }
        dispatch(dataFetched());
    } catch (error) {
        dispatch(logoutUser());
    }
}

// Action creators are generated for each case reducer function
export const { setUser, setLoading, setLoginErrorMessage, logoutUser, dataFetched } = authSlice.actions;
export { loginStoreUser };
export default authSlice.reducer;