import * as React from 'react';
import { Form, Field } from 'react-final-form';
import { FORM_ERROR } from 'final-form';

import { renderHiddenField, renderTextField, FileField } from '../../../components/inputs';
import { DangerOutlineButton, PrimaryOutlineButton, SecondaryOutlineButton } from '../components/CustomElements';

const formStyle = {
    field: {
        marginBottom: '10px'
    }
}

const VideoForm = (props) => {
    return (
        <Form
            onSubmit={props.onSubmit}
            validate={props.validate || null}
            initialValues={props.initialValues}
            render={({ handleSubmit, submitting, pristine, values }) => (
                <form component="form" onSubmit={handleSubmit} noValidate >
                    <span style={{fontWeight:'bold'}}>Incluir novo vídeo</span>
                    <Field name="_id"  component={renderHiddenField}/>
                    <Field
                        sx={formStyle.field}
                        fullWidth
                        name="name"
                        variant='standard'
                        component={renderTextField}
                        label="Nome do Video"
                        />

                    <Field
                        sx={formStyle.field}
                        fullWidth 
                        name="description"
                        variant='standard'
                        component={renderTextField}
                        label="Descrição curta do video para referência"
                        />
                    <FileField
                        style={formStyle.field}
                        label="Escolher video"
                        name="file"
                        multiple={false}
                        />

                    {props.alertComponent}

                    <PrimaryOutlineButton 
                        label={values?._id ? 'Atualizar':'Salvar'}
                        type="submit" 
                        disabled={submitting || pristine}/>

                    <SecondaryOutlineButton
                        label='Cancelar'
                        type="button" 
                        onClick={()=> props?.onClose(values)}/>

                    {values?._id && <DangerOutlineButton 
                        label='Excluir'
                        type="button" 
                        confirmMessage="Você confirma a exclusão do vídeo?"
                        onClick={() => props?.onPressDelete(values)}/>}
                </form>
            )}/>
    );
}

export default VideoForm;