import * as React from 'react';
import { Form, Field } from 'react-final-form';
import { FORM_ERROR } from 'final-form';
import { FileField, renderHiddenField, renderTextField } from '../../../components/inputs';
import { DangerOutlineButton, PrimaryOutlineButton, SecondaryOutlineButton } from '../components/CustomElements';

const CourseForm = (props) => {
    return (
        <Form
            onSubmit={props.onSubmit}
            validate={props.validate || null}
            initialValues={props.initialValues}
            render={({ handleSubmit, submitting, pristine, values }) => (
                <form component="form" onSubmit={handleSubmit} noValidate >
                    <span style={{fontWeight:'bold'}}>Incluir novo treinamento</span>
                    <Field name="_id"  component={renderHiddenField}/>

                    <Field
                        name="name"
                        component={renderTextField}
                        label="Nome do Treinamento"
                        />

                    <Field
                        name="slug"
                        component={renderTextField}
                        label="Slug do Treinamento"
                        />

                    <Field
                        name="color"
                        component={renderTextField}
                        label="Cor do Treinamento"
                        />

                    <FileField
                        label="Escolher icone"
                        name="file"
                        multiple={false}
                        />

                    {!!values?._id && (
                        <div class="form-group">
                            <SecondaryOutlineButton 
                                disabled={values.index <= 1}
                                onClick={()=> props?.mudarOrdem(values?._id, values.index, values.index - 1)}
                                label={'Subir na ordem'}
                                />

                            <SecondaryOutlineButton 
                                disabled={values.index == props.cursosCount}
                                onClick={()=> props?.mudarOrdem(values?._id, values.index, values.index + 1)}
                                label={'Descer na ordem'}
                                />
                        </div>
                    )}

                    <PrimaryOutlineButton 
                        disabled={submitting || pristine}
                        label={values?._id ? 'Atualizar':'Salvar'}
                        type='submit'
                    />

                    <SecondaryOutlineButton
                        onClick={()=> props?.onClose(values)}
                        label='Cancelar'
                    />

                </form>
            )}/>
    );
}

export default CourseForm;