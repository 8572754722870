import * as React from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';

export default function NavigationMenu(props) {

    let navigate = useNavigate();
    const { pathname } = useLocation();

    const menuItens = [
        {name: "Dashboard", path: "/dashboard", icon: 'feather icon-home' },
        {name: "Treinamentos", path: "/dashboard/courses", icon: 'feather icon-home' },
        {name: "Associados", path: "/dashboard/app-users", icon: 'feather icon-users' },
        {name: "Conversas", path: "/dashboard/chat", icon: 'feather icon-mail' },
        {name: "Videos", path: "/dashboard/videos", icon: 'feather icon-video' },
        {name: "News", path: "/dashboard/news", icon: 'feather icon-file-text' },
        {name: "Administradores", path: "/dashboard/admin-users", icon: 'feather icon-users' },
    ];

    const navItem = ({path,name,icon}) => {
        let active = (pathname == path) ? ' active ':'';
        return (
            <li key={name} className={"nav-item" + active} >
                <Link to={path} className={"nav-link"}>
                    <span className="pcoded-micon"><i className={icon}></i></span>
                    <span className="pcoded-mtext">{name}</span>
                </Link>
            </li>
        )
    }

    return (
        <nav className="pcoded-navbar">
            <div className="navbar-wrapper">
                <div className="navbar-brand header-logo">
                <Link to="/" className="b-brand">
                    <img src='/assets/images/logo-thumb.png' style={{width:'35px'}}/>
                    <span className="b-title">FiClub</span>
                </Link>
                <div className="mobile-menu" id="mobile-collapse"><span></span></div>
            </div>
                <div className="navbar-content scroll-div">
                    <ul className="nav pcoded-inner-navbar">
                        <li className="nav-item pcoded-menu-caption">
                            <label>Menu</label>
                        </li>
                        {
                            menuItens.map(item => navItem(item))
                        }
                    </ul>
                </div>
            </div>
        </nav>
    );
}
