import * as React from 'react';
import { useDispatch } from "react-redux";
import * as LayoutActions from '../../../store/Layout';
import API from '../../../services/api';

import PageToolbar from '../components/Toolbar';
import { PrimaryButton, ShadowedBox } from '../components/CustomElements';
import CourseForm from '../forms/form-course';
import { useNavigate } from 'react-router-dom';
import { AlertDanger } from '../components/Alerts';
import CourseService from '../../../services/courseServices';

const courseService = new CourseService();

const cursoItemStyle = {
    image: {
        height:'80px',
        marginBottom: '15px'
    },
    text: {
        color: '#000',
        fontSize: '18px',
        marginLeft: '15px'
    }
};

const moreIconStyle = {
    div: {
        background:'#3f4d6730',
        width: 30,
        height: 30,
        position:'absolute',
        right: 20,
        top: 5,
        zIndex:10,
        alignItems:'center',
        justifyContent: 'center',
        display: 'flex',
        borderRadius: '50%'
    }
}

const Cursos = () => {
    
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [courses,setCourses] = React.useState([]);
    const [formVisible,setFormVisible] = React.useState(false);
    const [selectedCourse,selectCourse] = React.useState(null);
    const [searchString,setSearchString] = React.useState("");
    const [formError,setFormError] = React.useState("");

    // set Page settings
    React.useEffect(() => {
        // Atualiza o titulo do documento usando a API do browser
        dispatch(LayoutActions.setPage({
            title: "Treinamentos",
            subTitle: 'Lista dos treinamentos cadastrados no aplicativo'
        }));

        getCourses();

    },[]);

    const getCourses = async () => {
        try {
            let courses = await API.getCourses();
            setCourses(courses);
        } catch (error) {
            console.log(error.response);
        }
    }

    const navigateToClass = (_id) => {
        navigate(_id);
    }

    const handleCourseDelete = (course) => {
        console.log(course)
    }

    const handleSubmit = async (values) => {
        const payload = new FormData();
        payload.append("name", values.name);
        payload.append("slug", values.slug);
        payload.append("color", values.color);
        if ('file' in values) payload.append("file", values.file[0]);

        try {
            if ('_id' in values) {
                let response = await API.editCourse(values._id,payload);
            } else {
                let response = await API.addCourse(payload);
            }
            getCourses();
            handleAddClick();
        } catch (error) {
            if (error.data) setFormError(error.data.message)
        }
    }

    const handleAddClick = () => {
        setFormVisible(!formVisible);
        selectCourse(null);
    }

    const editCurso = (curso) => {
        selectCourse(curso._id);
        setFormVisible(true);
    }

    const onSearchChange = (search) => {
        setSearchString(search)
    }

    const handleMudarOrdem =  async ({_id, anterior, proximo}) => {
        await courseService.mudarOrdemCurso(_id, {anterior, proximo});
        await getCourses();
    }

    return (
        <div>
            <PageToolbar toolbarStyle={{display: 'none'}}>
                <PrimaryButton className={'m-0'} onClick={handleAddClick}>{!formVisible ? 'Incluir novo treinamento':'Cancelar'}</PrimaryButton>
                <PageToolbar.Searchbar onChange={onSearchChange} value={searchString} />
            </PageToolbar>
            <div className='row'>
                {formVisible && <div className='col-sm-4' >
                    <ShadowedBox>
                        <CourseForm 
                            onClose={() => handleAddClick()}
                            onPressDelete={(course) => handleCourseDelete(course)}
                            mudarOrdem={(_id, anterior, proximo) => handleMudarOrdem({_id, anterior, proximo})}
                            initialValues={courses.find(c => c._id === selectedCourse)}
                            cursosCount={courses.length}
                            onSubmit={(data)=>handleSubmit(data)}/>
                        <AlertDanger dismissable onClose={() => setFormError("")} message={formError}/>
                    </ShadowedBox>
                </div>}
                <div className={formVisible ? 'col-sm-8' : 'col-sm-12'}>
                    <div className='row'>
                        {
                            courses.map(curso => (
                                <div className={formVisible ? 'col-xxl-4 col-6' : 'col-xxl-3 col-4'} style={{position:'relative'}}>
                                    <div onClick={() => editCurso(curso)} style={moreIconStyle.div}><i className="feather icon-more-vertical" style={{color:'white'}}></i></div>
                                    <ShadowedBox onClick={() => navigateToClass(curso._id)} clicable style={{backgroundColor: curso.color, borderRadius: 15}}>
                                        <div style={{display:'flex', flexDirection:'row', alignItems:'center'}}>
                                            <img src={curso.icon.location} style={cursoItemStyle.image}/>
                                            <span style={cursoItemStyle.text}>{curso.index} - {curso.name}</span>
                                        </div>
                                    </ShadowedBox>
                                </div>
                            ))
                        }
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Cursos;