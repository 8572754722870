import { Button } from '@mui/material';
import { styled, alpha } from '@mui/material/styles';

import './index.css';

const ShadowedBox = (props) => {
    let mainClasses = ['card'];

    props.hoverable && mainClasses.push('card-hoverable');
    props.clicable && mainClasses.push('card-hoverable','card-clicable');

    return (
        <div className={mainClasses.join(" ")} onClick={props.onClick} style={props.style}>
            <div className='card-block'>
                {props.children}
            </div>
        </div>
    )
}

const PrimaryButton = (props) => (
    <DefaultButton {...props} btnType='primary' />
)

const SecondaryButton = (props) => (
    <DefaultButton {...props} btnType='secondary' />
)

const DangerButton = (props) => (
    <DefaultButton {...props} btnType='danger' />
)

const PrimaryOutlineButton = (props) => (
    <DefaultButton {...props} btnType='outline-primary' />
)

const SecondaryOutlineButton = (props) => (
    <DefaultButton {...props} btnType='outline-secondary' />
)

const DangerOutlineButton = (props) => (
    <DefaultButton {...props} btnType='outline-danger' />
)

const DefaultButton = ({confirmMessage, btnType,className,label,children,isLoading,...props}) => {

    const handlePress = () => {
        if (confirmMessage) {
            if (window.confirm(confirmMessage) && props.onClick) {
                props.onClick();
                return true;
            }
            return false;
        }
        if(props.onClick) props.onClick();
        return true;
    }

    return (
        <button 
            className={'btn btn-'+btnType+' shadow-1 ' + className}
            type='button'
            {...props}
            onClick={handlePress}
            >
            {isLoading && <span class="spinner-border spinner-border-sm mr-3" role="status" aria-hidden="true"></span>}
            {children || label }
        </button>
    )
}

export {
    PrimaryButton,
    ShadowedBox,
    PrimaryOutlineButton,
    SecondaryOutlineButton,
    SecondaryButton,
    DangerButton,
    DangerOutlineButton,
};