import * as React from 'react';
import { Form, Field } from 'react-final-form';
import { FORM_ERROR } from 'final-form';

import { renderHiddenField, renderTextField, FileField } from '../../../components/inputs';
import { DangerButton, PrimaryButton } from '../components/CustomElements';

const formStyle = {
    field: {
        marginBottom: '10px'
    }
}

const AppUserForm = (props) => {
    return (
        <Form
            onSubmit={props.onSubmit}
            validate={props.validate || null}
            initialValues={props.initialValues}
            render={({ handleSubmit, submitting, pristine, values }) => (
                <form component="form" onSubmit={handleSubmit} noValidate >
                    <Field name="_id"  component={renderHiddenField}/>
                    <Field
                        sx={formStyle.field}
                        fullWidth
                        name="name"
                        readOnly
                        component={renderTextField}
                        label="Nome"
                        />

                    <Field
                        sx={formStyle.field}
                        fullWidth 
                        name="email"
                        type='email'
                        readOnly
                        component={renderTextField}
                        label="Email"
                        />

                    <div className='form-row'>

                        <div className='col'>
                            <Field
                                sx={formStyle.field}
                                fullWidth 
                                name="genre"
                                readOnly
                                component={renderTextField}
                                label="Sexo"
                                />
                        </div>

                        <div className='col'>
                            <Field
                                sx={formStyle.field}
                                fullWidth
                                name="birthDate"
                                readOnly
                                component={renderTextField}
                                label="Nascimento"
                                />
                        </div>

                    </div>
                    <div className='form-row'>

                        <div className='col'>
                            <Field
                                sx={formStyle.field}
                                fullWidth
                                name="createdAt"
                                readOnly
                                component={renderTextField}
                                label="Cadastrado em"
                                />
                        </div>

                        <div className='col'>
                            <Field
                                sx={formStyle.field}
                                fullWidth 
                                name="lastLoginAt"
                                readOnly
                                component={renderTextField}
                                label="Último login em"
                                />
                        </div>

                    </div>

                    { values?.active ? 
                        <DangerButton confirmMessage="Você quer desativar o usuário?" onClick={() => props?.onToggleStatus(values)} label={"Desativar"} /> :
                        <PrimaryButton confirmMessage="Você quer reativar o usuário?" onClick={() => props?.onToggleStatus(values)} label={"Reactivar"} />
                    }
                </form>
            )}/>
    );
}

export default AppUserForm;