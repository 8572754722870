import * as React from 'react';
import { useDispatch, useSelector } from "react-redux";
import * as LayoutActions from '../../../store/Layout';
import API from '../../../services/api';

import Box from '@mui/material/Box';
import { Alert, AppBar, Divider, Drawer, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Paper } from '@mui/material';
import PageToolbar from '../components/Toolbar';
import { PrimaryButton, ShadowedBox } from '../components/CustomElements';
import { useNavigate, useParams } from 'react-router-dom';
import dayjs from 'dayjs';

const Classes = (props) => {
    
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const params = useParams();
    const [lessons,setLessons] = React.useState([]);

        
    // set Page settings
    React.useEffect(() => {
        // Atualiza o titulo do documento usando a API do browser
        dispatch(LayoutActions.setPage({
            title: "Treinos",
            subTitle: 'Lista dos treinos cadastrados no aplicativo'
        }));

        getLessons();

    },[]);

    const getLessons = async () => {
        try {
            let lessons = await API.getLessons(params.courseId);
            console.log(lessons);
            setLessons(lessons);
            
        } catch (error) {
            console.log(error.response);
        }
    }

    const navigateToAula = (lessonId) => {
        if (lessonId) {
            navigate(`/dashboard/courses/${params.courseId}/edit/${lessonId}`)
        } else {
            navigate(`/dashboard/courses/${params.courseId}/add`)
        }
    }

    return (
        <div>
            <PageToolbar>
                <PrimaryButton onClick={() => navigateToAula(null)} label="Novo treino"/>
                <PageToolbar.Searchbar/>
            </PageToolbar>

            <div className=''>
                <div className='row'>
                {
                    lessons.map(lesson => (
                        <div className='col-xxl-4 col-xl-6 col-sm-12'>
                            <ShadowedBox onClick={()=>navigateToAula(lesson._id)} key={lesson._id} style={{marginBottom:'10px', display:'flex'}} clicable>
                                <div className="media">
                                    <img src={lesson?.icon?.location} style={{height:'100px',marginRight:'35px'}} className="align-self-center "/>
                                    <div className="media-body">
                                        <h5 className="mt-0">{lesson.title}</h5>
                                        <p>{lesson.subTitle}</p>
                                        <p>
                                            <i class="feather icon-clock"></i> {dayjs(lesson.createdAt).format("DD/MM/YYYY")}<br/>
                                            <i class="feather icon-video"></i> {lesson.videoId?.name}<br/>
                                            <i class="feather icon-eye"></i> {lesson.views} visualizações<br/>
                                            <i class="feather icon-star"></i> {lesson.score ? lesson.score.toFixed(2) : 'Sem avaliação' } média
                                        </p>
                                    </div>
                                </div>
                            </ShadowedBox>
                        </div>
                    ))
                }
                </div>
            </div>
        </div>
    );
}

export default Classes;