import * as React from 'react';
import { useDispatch, useSelector } from "react-redux";
import * as LayoutActions from '../../../store/Layout';
import API from '../../../services/api';

import { useNavigate, useParams } from 'react-router-dom';
import { convertToRaw } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import { EditorState, ContentState } from 'draft-js';
import { AlertDanger } from '../components/Alerts';
import NewsForm from '../forms/form-news';

const AddNews = () => {
    
    const dispatch = useDispatch();
    const [videos,setVideos] = React.useState([]);
    const [news, setNews] = React.useState({});
    const { newsId } = useParams();
    const [formError,setFormError] = React.useState("");
    const navigate = useNavigate();

    const getNews = async () => {
        let data = await API.getNews(newsId);

        const { contentBlocks, entityMap } = htmlToDraft(data.contentHtml);
        const contentState = ContentState.createFromBlockArray(contentBlocks, entityMap);
        data.contentHtml = EditorState.createWithContent(contentState);
        data.postedAt = data.postedAt.substr(0,10);
        data.active = data.active ? 1:0

        setNews(data)
    }

    // set Page settings
    React.useEffect(() => {
        // Atualiza o titulo do documento usando a API do browser
        dispatch(LayoutActions.setPage({
            title: newsId ? "Editar Treino" :"Novo Treino",
        }));

        if (newsId) { getNews() }

        getVideos();

    },[]);

    const handleSubmit = (data) => {
        let contentHtml = convertToRaw(data.contentHtml.getCurrentContent());
        contentHtml = draftToHtml(contentHtml);
        addLesson(data,contentHtml)
    }

    const addLesson = async (values,contentHtml) => {
        console.log(values,contentHtml);
        let {title, subTitle, active, file, postedAt} = values;
        let payload = new FormData();
        payload.append("title", title);
        payload.append("subTitle", subTitle);
        payload.append("contentHtml", contentHtml);
        payload.append("postedAt", postedAt);
        payload.append("status", active);
        if('file' in values) payload.append("file", file[0]);
        try {
            if ('_id' in values) {
                let response = await API.editNews(values._id,payload);
                getNews();
            } else {
                let response = await API.addNews(payload);
                navigate('/dashboard/news');
            }
        } catch (error) {
            console.log("error",error)
            if(error.data) setFormError(error.data.message);
        }
    }

    const getVideos = async () => {
        try {
            let videos = await API.getVideos();
            setVideos(videos);
        } catch (error) {
            console.log(error.response);
        }
    }

    return (
        <NewsForm 
            initialValues={news}
            onSubmit={(data) => handleSubmit(data)}
            videoOptions={videos}
            alertComponent={<AlertDanger dismissable onClose={() => setFormError("")} message={formError}/>}
            onClose={() => navigate('/dashboard/news')}
            />
    );
}

export default AddNews;