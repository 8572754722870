import * as React from 'react';
import { useDispatch, useSelector } from "react-redux";
import * as LayoutActions from '../../../store/Layout';
import API from '../../../services/api';

import Button from '@mui/material/Button';
import PageToolbar from '../components/Toolbar';
import VideoForm from '../forms/form-videos';
import { PrimaryButton, ShadowedBox } from '../components/CustomElements';
import { AlertDanger } from '../components/Alerts';

const videoItemStyle = {
    image: {
        width:'120px',
        display:'block',
        marginLeft:'auto',
        marginRight:'auto'
    },
    text: {
        display:'block',
        fontSize: '18px',
        marginTop: '15px'
    },
    description: {
        fontSize: '14px',

    },
    videoContainer: {
        background:'#000',
        height:'250px'
    },
    info: {
        position:'relative'
    },
    iconMore: {
        position:'absolute',
        right: 0,
        top:0
    }
};
 

const Videos = () => {
    
    const dispatch = useDispatch();
    const [videos,setVideos] = React.useState([]);
    const [formVisible,setFormVisible] = React.useState(false);
    const [progress,setProgress] = React.useState(['',0]);
    const [selectedVideo,selectVideo] = React.useState({});
    const [formError,setFormError] = React.useState("");

        
    // set Page settings
    React.useEffect(() => {
        // Atualiza o titulo do documento usando a API do browser
        dispatch(LayoutActions.setPage({
            title: "Videos",
            subTitle: 'Videos cadastrados, para ser usados nos treino'
        }));

        getVideos();

    },[]);

    const getVideos = async () => {
        try {
            let videos = await API.getVideos();
            setVideos(videos);
            
        } catch (error) {
            console.log(error.response);
        }
    }

    const progressBar = () => progress[1] != 0 && (
        <>
            <small>{progress[0]}</small>
            <div className="progress">
                <div className="progress-bar" role="progressbar" style={{width: progress[1] + '%'}}>{progress[1]}%</div>
            </div>
        </>
    )

    const addNewVideo = async (values) => {
        let { name, description } = values;
        let video = await API.addVideo({name,description});
        if (video) {
            const payload = new FormData();
            payload.append("file", values.file[0]);
            let request = await API.uploadVideo(
                video._id,
                payload,
                uploadProgress => {
                    let data = uploadProgress;
                    const percentage = Math.round( (data.loaded * 100) / data.total );
                    setProgress(['Fazendo Upload',percentage]);
                    console.log("uploadProgress",percentage);
                },progressEvent => {
                    let chunks = progressEvent.currentTarget.response.split("<<payload>>");
                    let data = JSON.parse(chunks[chunks.length-1]);
                    console.log("data",data);
                    const percentage = Math.round( (data.loaded * 100) / data.total );
                    setProgress(['Processando Video',percentage]);
                });

            getVideos();
            handleAddClick();
        }
    }
    
    const editVideo = async (values) => {
        let { _id, name, description } = values;
        let video = await API.editVideo(_id,{name,description});
        if (video) {
            if ('file' in values) {
                const payload = new FormData();
                payload.append("file", values.file[0]);
                await API.uploadVideo(
                    video._id,
                    payload,
                    uploadProgress => {
                        let data = uploadProgress;
                        const percentage = Math.round( (data.loaded * 100) / data.total );
                        setProgress(['Fazendo Upload',percentage]);
                    },progressEvent => {
                        let chunks = progressEvent.currentTarget.response.split("<<payload>>");
                        let data = JSON.parse(chunks[chunks.length-1]);
                        const percentage = Math.round( (data.loaded * 100) / data.total );
                        setProgress(['Processando Video',percentage]);
                    })
            }
        }
        getVideos();
        handleAddClick();
    }

    const handleSubmit = (values) => {
        try {
            if(!values._id) addNewVideo(values);
            else editVideo(values);
        } catch (error) {
            console.log(error);
        }
        
    }
    
    const handleOpenVideo = (video) => {
        setFormVisible(true);
        selectVideo(video);
        window.scrollTo(0, 0)
    }

    const handleAddClick = () => {
        setFormVisible(!formVisible);
        selectVideo({});
        setProgress(['',0]);
    }

    const handleVideoDelete = async (video) => {
        try {
            await API.deleteVideo(video._id);
            getVideos();
            handleAddClick();
        } catch (response) {
            if (response.data)
                setFormError(response.data.message)
        }
    }

    return (
        <div>
            <PageToolbar>
                <PrimaryButton className={'m-0'} onClick={handleAddClick}>{!formVisible ? 'Incluir novo vídeo':'Cancelar'}</PrimaryButton>
                <PageToolbar.Searchbar/>
            </PageToolbar>
            <div className='row' >
                {formVisible && <div className='col-md-4' >
                    <div className='card' style={{}}>
                        <div className='card-block'>
                            <VideoForm 
                                alertComponent={<AlertDanger className='mb-3' dismissable onClose={() => setFormError("")} message={formError}/>}
                                onClose={() => handleAddClick()} 
                                onPressDelete={(video) => handleVideoDelete(video)} initialValues={selectedVideo} onSubmit={(data)=>handleSubmit(data)}/>
                            
                            {progressBar()}
                        </div>
                    </div>
                </div>}
                <div className={formVisible ? 'col-sm-8' : 'col-sm-12'}>
                    <div className='row'>
                        {
                            videos.map(video => (
                                <div className={formVisible ? 'col-xxl-4 col-xl-6 col-sm-12' : 'col-xxl-3 col-xl-4 col-sm-6'}>
                                    <ShadowedBox onClick={() => handleOpenVideo(video)}>
                                        <div style={videoItemStyle.videoContainer}>
                                            <video width={'100%'} height={'250px'} controls src={video.video?.location} type="video/mp4"/>
                                        </div>
                                        <div style={videoItemStyle.info}>
                                            <span style={videoItemStyle.text}>{video.name}</span>
                                            <span style={videoItemStyle.description}>{video.description}</span>
                                            <div>
                                                <small>{video.createdAt}</small>
                                            </div>
                                        </div>
                                    </ShadowedBox>
                                </div>
                            ))
                        }
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Videos;