import * as React from 'react';
import { Form, Field } from 'react-final-form';
import { FORM_ERROR } from 'final-form';

import { Editor } from "react-draft-wysiwyg";
import { EditorState } from "draft-js";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

import { renderHiddenField, renderTextField, renderSelectField, FileField, TagsInputField } from '../../../components/inputs';
import { PrimaryOutlineButton, SecondaryOutlineButton } from '../components/CustomElements';
import { AlertDanger } from '../components/Alerts';

const formStyle = {
    field: {
        marginBottom: '10px'
    }
}

const ClassForm = (props) => {
    
    return (
        <Form
            onSubmit={props.onSubmit}
            validate={props.validate || null}
            initialValues={props.initialValues}
            render={({ handleSubmit, submitting, pristine, submitError }) => (
                <form onSubmit={handleSubmit} className='row'>
                    <div className='col-5'>
                        <div className='card'>
                            <div className='card-block'>
                                <Field name="_id"  component={renderHiddenField}/>
                                <Field
                                    name="title"
                                    required
                                    component={renderTextField}
                                    label="Titulo do treino"
                                    />

                                <Field
                                    name="subTitle"
                                    required
                                    component={renderTextField}
                                    label="Breve descrição do treino"
                                    />

                                <Field
                                    name="videoId"
                                    required
                                    component={renderSelectField}
                                    label="Selecione o vídeo"
                                >
                                    <option value={null}>Selecione</option>
                                    {
                                        props?.videoOptions.map( video => (
                                            <option key={video._id} value={video._id}>{video.name}</option>
                                        ))
                                    }
                                </Field>

                                <Field
                                    name="tags"
                                    component={TagsInputField}
                                    label="Categorias do treino"
                                    />

                                <FileField
                                    label="Escolher icone"
                                    name="file"
                                    accept="image/png, image/jpeg"
                                    multiple={false}
                                    />
                                <PrimaryOutlineButton 
                                    isLoading={props.isLoading}
                                    type="submit"
                                    confirmMessage="Você confirma a alteração?"
                                    disabled={submitting || pristine || props.isLoading}
                                    label='Salvar'
                                />
                                <SecondaryOutlineButton
                                    type='button'
                                    label='Cancelar'
                                    onClick={props.onClose}
                                />
                                {submitError && <AlertDanger className="mt-3">{submitError}</AlertDanger>}
                                { props.alertComponent }
                            </div>
                        </div>
                    </div>
                    <div className='col-7'>
                        <div className='card'>
                            <div className='card-block'>
                                <div>
                                    <span>Conteúdo escrito:</span>
                                </div>
                                <Field
                                    name="contentHtml"
                                    >
                                    {({ input: { value, onChange, ...input } }) => (
                                        <Editor 
                                            toolbar={{
                                                options: ['inline', 'fontSize', 'list', 'textAlign', 'link', 'image'],
                                            }}
                                            placeholder="Começe a escrever o conteúdo do treino aqui..."
                                            editorState={value || EditorState.createEmpty()} onEditorStateChange={onChange} {...input} />
                                    )}
                                </Field>
                            </div>
                        </div>
                    </div>
                </form>
            )}/>
    );
}

export default ClassForm;