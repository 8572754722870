import * as React from 'react';

export default function MainBody({children}) {

    return (
        <div className='pcoded-main-container'>
            <div className='pcoded-wrapper'>
                <div className='pcoded-content'>
                    <div className='pcoded-inner-content'>
                        {children}
                    </div>
                </div>
            </div>
        </div>
    );
}
