import * as React from 'react';
import { useDispatch, useSelector } from "react-redux";
import * as LayoutActions from '../../../store/Layout';
import API from '../../../services/api';
import FlatList from '../../../components/FlatList';
import PageToolbar from '../components/Toolbar';
import { PrimaryButton } from '../components/CustomElements';
import { useNavigate } from 'react-router-dom';
import dayjs from 'dayjs';
  
const News = () => {
    
    const dispatch = useDispatch();
    const [news,setNews] = React.useState([]);
    const [searchString,setSearchString] = React.useState("");
    const navigate = useNavigate();

    const getNews = async () => {
        setNews(await API.getNews());
    }
    
    // set Page settings
    React.useEffect( () => {
        // Atualiza o titulo do documento usando a API do browser
        dispatch(LayoutActions.setPage({
            title: "Noticias"
        }));
        getNews();
    },[]);

    const onSearchChange = (searchString) => {
        setSearchString(searchString)
    }

    const navigateToNews = (id) => {
        if (id) {
            navigate(`/dashboard/news/edit/${id}`)
        } else {
            navigate(`/dashboard/news/add`)
        }
    }

    return (
        <div className='row'>
            <div className="col-xl-12">
                <PageToolbar>
                    <PrimaryButton className={'m-0'} onClick={() => navigateToNews(null)} label="Novo treino"/>
                    <PageToolbar.Searchbar onChange={onSearchChange} value={searchString} />
                </PageToolbar>
            </div>
            <div className="col-xl-12">
                <div className="card">
                    <div className="table-card-block">
                        <div className="table-responsive">
                            <table className="table table-hover">
                                <thead>
                                    <tr>
                                        <th>Titulo</th>
                                        <th>Autor</th>
                                        <th>Status</th>
                                        <th>Criado em</th>
                                        <th>Postado em</th>
                                        <th>Visualizações</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <FlatList
                                        data={news}
                                        renderItem={(n,i) => {
                                            return (
                                                <tr key={i} onClick={() => navigateToNews(n._id)}>
                                                    <td>{n.title}</td>
                                                    <td>{n.author.name}</td>
                                                    <td>{n.active ? "Postado" : "Não Postado"}</td>
                                                    <td>{dayjs(n.createdAt).format("DD/MM/YYYY")}</td>
                                                    <td>{dayjs(n.postedAt).format("DD/MM/YYYY")}</td>
                                                    <td>{n.views}</td>
                                                </tr>
                                            )
                                        }}
                                        renderEmpty={()=>(
                                            <tr>
                                                <td>Sem itens para mostrar</td>
                                            </tr>
                                        )}
                                    />
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    );
}

export default News;